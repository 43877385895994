import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Theming from '../../../constants/theming';
import LoadingButton from '../../templates/LoadingButton';
import payAt from './payment.jpeg';

interface FnInterfaceProps {
    amount: number,
    show: Boolean,
    onHide: () => void,
    onFormSubmit: (paymentMethod: 'bank_transfer' | 'upi' | 'other', paymentProof: File) => Promise<void>,
}

export function MembershipModal(props: FnInterfaceProps) {
    const [isChecked, setChecked] = useState(false);
    const formRef = React.createRef<HTMLFormElement>();

    const handleChange = () => {
        setChecked(!isChecked)
    }

    const handleFormSubmit = () => {
        const formData = new FormData(formRef.current as HTMLFormElement);
        const paymentMethod = formData.get('payment_mode') as 'bank_transfer' | 'upi' | 'other';
        const paymentProof = formData.get('payment_proof') as File;
        return props.onFormSubmit(paymentMethod, paymentProof);
    }

    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="text-uppercase" style={{ backgroundColor: Theming.backgroundColourLight, color: Theming.textColourLight }} closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Membership Form
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form ref={formRef}>
                    <div className="container py-3 text-center">
                        <img src={payAt} alt="UPICode" width={400} />
                        <p>Please transfer Rs. {props.amount} to the following UPI/Bank account</p>
                    </div>
                    <hr />
                    <div>
                        <label htmlFor="payment_mode" className="required">Payment mode:</label>
                        <select id="payment_mode" name="payment_mode" className="form-control" required>
                            <option value="">Select a payment mode</option>
                            <option value="bank_transfer">Net banking/NEFT</option>
                            <option value="upi">UPI (Google pay / Phone pe / Paytm)</option>
                            <option value="other">Other methods</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="payment_proof" className="required">Add Payment Proof (Screenshot of payment ):</label>
                        <input type="file" id="payment_proof" name="payment_proof" className="form-control" required accept="image/*" max-size="10MB" />
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" checked={isChecked} onChange={handleChange} className="custom-control-input" id="checkedPaid" />
                        <label className="custom-control-label" htmlFor="checkedPaid">I have paid the amount</label>
                    </div>
                    <div className="md-form">
                        <LoadingButton onClick={handleFormSubmit} disabled={!isChecked}>Submit</LoadingButton>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}