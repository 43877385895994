import { auth } from "./firebase";
import * as db from "./firestore";
import firebase from "firebase/app";
import { toast } from 'react-toastify';
import { delayed } from "../helpers/promise_helpers";
import AuthService from '../services/auth_service';

export const currentUser = auth.currentUser;

export const onAuthStateChanged = auth.onAuthStateChanged;

// Sign Up
export const doCreateUserWithEmailAndPassword = async (
  email: string,
  password: string,
  username: string
) => {
  const user = (await auth.createUserWithEmailAndPassword(email, password)).user;

  if (user) {
    user.sendEmailVerification();

    await user.updateProfile({
      displayName: username
    });

    // Create a user in your own accessible Firebase Database too
    await db.doCreateUser(user.uid, username, email);
  }

  return user;
};

// Update user to add User Name
export const doProfileUpdate = async (profile: {
  displayName?: string | null;
  photoURL?: string | null;
}) => {
  if (currentUser) {
    await currentUser.updateProfile(profile)?.then(() => db.doUpdateUser(currentUser.uid, profile));
    doBackendUserUpdate();
  }
};

// Sign In
export const doSignInWithEmailAndPassword = (email: string, password: string) =>
  auth.signInWithEmailAndPassword(email, password);

// Sign out
export const doSignOut = () => auth.signOut();

// Password Change
export const doPasswordUpdate = async (password: string) => {
  try {
    if (auth.currentUser)
      await auth.currentUser.updatePassword(password).then(() => {
        toast.success('Password Changed Successfully!', { autoClose: 3000 });
        return delayed<void>(3000);
      });
  } catch (e) {
    throw Error("No auth.currentUser!");
  }
};

// Password Reset
export const doPasswordReset = (email: string) =>
  auth.sendPasswordResetEmail(email).then(() => {
    toast.success('Email Sent Successfully!', { autoClose: 3000 });
    return delayed<void>(3000);
  });

// Send Verification Mail
export const doSendVerificationMail = () =>
  auth.currentUser?.sendEmailVerification();

// Signup Using Google OAuth
export const doSignInUsingGoogle = async () => {
  var provider = new firebase.auth.GoogleAuthProvider();
  try {
    const userCred = await firebase.auth().signInWithPopup(provider);
    const user = userCred.user;
    if (user)
      db.doCreateUser(user.uid, user.displayName || '', user.email || '');

    toast.success('Sign In Successful!', { autoClose: 3000 });
    return await delayed(3000, userCred);
  } catch (e) {
    toast.error(e.message);
  }
};

/**
 * Server Backend related auth services
 */
export const doBackendAuth = async (user: firebase.User) => {
  const token = await user.getIdToken();
  const authResponse = await AuthService.authByIdToken(user.uid, token);

  return authResponse?.user;
};

const doBackendUserUpdate = () => {
  if (AuthService.isSignedIn())
    return AuthService.updateUser();
};

auth.onAuthStateChanged(user => {
  if (!user)
    return AuthService.logOut();
});

auth.onIdTokenChanged(user => {
  if (user && AuthService.isSignedIn())
    return doBackendUserUpdate();
});
