import React from "react";
import "./form.styles.css";
import Theming from "../../../constants/theming";
import { Membership, MembershipDetails } from "../../../models/membership";
import { MembershipModal } from "./modal";
import MembershipService from '../../../services/membership_service';

interface MembershipFormProps {
    formData: Partial<MembershipDetails>
    onSubmit: (formValues: Membership) => void
}

interface MembershipFormState {
    modalShow: Boolean
    formData?: MembershipDetails
}

class MembershipForm extends React.Component<MembershipFormProps, MembershipFormState> {
    constructor(props: MembershipFormProps) {
        super(props);
        this.state = { modalShow: false };
    }

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formValues: MembershipDetails = {
            name: formData.get("name") as string,
            age: parseInt(formData.get("age") as string),
            email: formData.get("email") as string,
            why_join: formData.get("why_join") as string,
            membership_category: formData.get("membership_category") as 'student' | 'research_scholar' | 'professional',
            contact_number: formData.get("contact_number") as string,
            pan_card: formData.get("pan_number") as string,
            aadhar_card: formData.get("aadhar_number") as string,
            address: formData.get("address") as string
        };
        this.setState({ formData: formValues });
        this.toggleModal(true);
    };

    handleModalSubmit = async (paymentMethod: 'bank_transfer' | 'upi' | 'other', proofFile: File) => {
        const membership = await MembershipService.applyMembership(this.state.formData as MembershipDetails, paymentMethod, proofFile);
        this.toggleModal(false);
        this.props.onSubmit(membership);
    }

    toggleModal = (toggle: boolean) => {
        this.setState((prev) => ({
            ...prev,
            modalShow: toggle
        }));
    }

    getAmount = () => {
        switch (this.state.formData?.membership_category) {
            case 'student':
                return 500;
            case 'research_scholar':
                return 1500;
            case 'professional':
                return 2000;
            default:
                return null;
        }
    }

    render(): React.ReactNode {
        const amount = this.getAmount();
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name" className="required">Name:</label>
                        <input type="text" id="name" name="name" className="form-control" readOnly value={this.props.formData.name} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="age" className="required">Age:</label>
                        <input type="number" id="age" name="age" className="form-control" required min={1} max={100} defaultValue={this.props.formData.age} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" className="required">Email:</label>
                        <input type="email" id="email" name="email" className="form-control" readOnly value={this.props.formData.email} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="why_join" className="required">Why do you want to join our Society?:</label>
                        <textarea id="why_join" name="why_join" className="form-control" required defaultValue={this.props.formData.why_join}></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor="membership_category" className="required">Membership Category:</label>
                        <select id="membership_category" name="membership_category" className="form-control" required defaultValue={this.props.formData.membership_category}>
                            <option value="">Select a category</option>
                            <option value="student">Category 1 : Student membership (UG & PG) - Rs. 500</option>
                            <option value="research_scholar">Category 2 : Research Scholar membership (Mphil /Ph.d/ Post. Doc) - Rs. 1500</option>
                            <option value="professional">Category 3 : Academician / Professional membership – Rs. 2000</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="contact_number" className="required">Contact number:</label>
                        <input type="tel" id="contact_number" name="contact_number" className="form-control" required pattern="[0-9]{10}" defaultValue={this.props.formData.contact_number} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="pan_number">PAN Number (Optional):</label>
                        <input type="text" id="pan_number" name="pan_number" className="form-control" defaultValue={this.props.formData.pan_card} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="aadhar_number" className="required">Aadhar Number:</label>
                        <input type="text" id="aadhar_number" name="aadhar_number" className="form-control" required pattern="[0-9]{12}" defaultValue={this.props.formData.aadhar_card} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="address" className="required">Address:</label>
                        <textarea id="address" name="address" className="form-control" required defaultValue={this.props.formData.address}></textarea>
                    </div>
                    <button
                        className="btn"
                        type="submit"
                        style={{
                            color: Theming.textColourDark,
                            borderColor: Theming.textColourDark,
                            backgroundColor: Theming.backgroundColourDark
                        }}>
                        Buy Membership
                    </button>
                </form>
                {amount && <MembershipModal amount={amount} show={this.state.modalShow} onHide={() => this.toggleModal(false)} onFormSubmit={this.handleModalSubmit} />}
            </div>
        )
    }
}

export default MembershipForm;