import { BackendUser } from '../models/backend_user';
import CoreService from './core_service';
import RuntimeCache from './runtime_cache_service';

class AuthService extends CoreService {
    static _instance: AuthService;
    static getInstance = () => {
        if (!AuthService._instance) {
            return AuthService._instance = new AuthService();
        }
        return AuthService._instance;
    };

    private _prev_token?: string;
    private _user?: BackendUser;

    getUser = () => this._user ? ({ ...this._user }) : null;

    getUserRole = () => this._user ? ({ ...this._user?.role }) : null;

    authByIdToken = async (uid: string, token: string) => {
        if (this._user && this._prev_token === token)
            return {
                jwt: this._getToken(),
                user: this._user
            };

        const res = await this._strapi.request(
            'POST',
            '/firebase-users/auth', {
            data: { uid, token }
        });

        if (res.jwt) {
            this._setToken(res.jwt);
            this._user = res.user;
            RuntimeCache.resetAll();
        }

        return res as { jwt: string; user: BackendUser; };
    };

    logOut = () => {
        this._setToken();
        RuntimeCache.resetAll();
    };

    updateUser = () =>
        this._strapi.request(
            'PUT',
            'firebase-users/me', {
            headers: this._withAuthHeader(),
        });

    deleteUser = async () => {
        await this._strapi.request(
            'DELETE',
            'firebase-users/me', {
            headers: this._withAuthHeader()
        });
        RuntimeCache.resetAll();
    };
}

export default AuthService.getInstance();