import React from 'react';
import { Link } from 'react-router-dom';
import PromiseLoader from '../../templates/PromiseLoader';
import DefaultProps from '../../../models/default_props';
import Theming from '../../../constants/theming';
import { AppContextSchema, withAppContext } from '../../app-context';
import MembershipService from '../../../services/membership_service';
import RoutePath from '../../../constants/route-paths';
import { MembershipDetails, Membership as MembershipModel } from '../../../models/membership';
import MembershipForm from './form';
import { toast, ToastContainer } from 'react-toastify';
import AuthService from '../../../services/auth_service';
import MediaHelpers from '../../../helpers/media_file_helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface InterfaceProps extends DefaultProps, AppContextSchema {
    isChecked: Boolean
}

interface InterfaceState {
    refresh: Boolean,
    formData?: MembershipDetails
}

class Membership extends PromiseLoader<MembershipModel[], InterfaceProps, InterfaceState> {
    async promise() {
        return MembershipService.getMembershipStatus()
    }

    constructor(props: InterfaceProps) {
        super(props);
        this.state = { refresh: false };
    }

    isMember() {
        return AuthService.getUserRole()?.type === 'member';
    }

    handleFormClick = (formData: MembershipDetails) => {
        this.setState((prev) => ({ ...prev, formData: formData, refresh: !this.state.refresh }));
    }

    handleCancel = async (id: String) => {
        await MembershipService.cancelMembership(id);
        this.setState({ refresh: !this.state.refresh })
    }

    formatMembershipCategory(category: 'student' | 'research_scholar' | 'professional') {
        if (category === 'student') {
            return 'Student';
        }
        else if (category === 'research_scholar') {
            return 'Research Scholar';
        }
        else if (category === 'professional') {
            return 'Academician / Professional';
        }
        else {
            return 'Unknown';
        }
    }

    render() {
        return (
            <main className="container my-5 py-5">
                <div className="container" style={{ color: Theming.textColourDark }}>
                    <h2 className="h2-responsive"><span style={{ color: Theming.backgroundColourDark }}>SHAM</span> Membership</h2>
                    <hr className="my-3"></hr>

                    <div className="jumbotron">
                        <h3 className="display-4">Membership @ SHAM</h3>
                        {super.render()}
                        <ToastContainer />
                    </div>
                </div>
            </main>
        )
    }

    loadingComponent() {
        return (
            <div className="justify-content-center">
                <div className="spinner-grow text-dark" role="status" style={{ backgroundColor: Theming.backgroundColourDark }}>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    dataComponent(membershipJson: MembershipModel[]) {
        const lastItem = membershipJson.length > 0 ? membershipJson[membershipJson.length - 1] : null;
        const pending = lastItem?.status.toLowerCase() === 'pending',
            rejected = lastItem?.status.toLowerCase() === 'rejected';

        if (this.isMember()) {
            return (
                <p>You are already a member!</p>
            );
        }
        else if (pending) {
            return (
                <div>
                    <p className="lead">Membership Requests</p>
                    <table className="table">
                        <thead className="white-text" style={{ backgroundColor: Theming.backgroundColourDark }}>
                            <tr>
                                <th scope="col">Contact</th>
                                <th scope="col">Age</th>
                                <th scope='col'>Category</th>
                                <th scope="col">Why Join?</th>
                                <th scope='col'>Payment Proof</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                membershipJson.map(item => (
                                    <tr>
                                        <td>{item.contact_number}</td>
                                        <td>{item.age}</td>
                                        <td>{this.formatMembershipCategory(item.membership_category)}</td>
                                        <td>{item.why_join}</td>
                                        <td>
                                            <OverlayTrigger
                                                placement="top"
                                                trigger={['click', 'hover', 'focus']}
                                                overlay={
                                                    <Tooltip id='tooltip' placement="top" title="Preview">
                                                        <img className='img-fluid' src={MediaHelpers.resolveMediaUrl(item.payment_proof?.formats.small.url!)} alt={item.payment_proof?.alternativeText} width="200" />
                                                    </Tooltip>}
                                            >
                                                <img src={MediaHelpers.resolveMediaUrl(item.payment_proof?.formats.thumbnail.url!)} alt={item.payment_proof?.alternativeText} width="30" height="30" className="thumbnail" data-toggle="tooltip" data-placement="top" title="Preview" />
                                            </OverlayTrigger>
                                        </td>
                                        <td>{item.status}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <p className="text-info">Your Membership Request has been submitted, please wait while we process your request.</p>
                </div>
            )
        }
        else {
            const formData = {
                ...lastItem,
                ...this.state.formData,
                name: this.props.isAuthenticated()?.displayName as string,
                email: this.props.isAuthenticated()?.email as string
            };
            if (rejected) {
                toast.error('Your membership request was rejected, please apply again!', { autoClose: 5000 });
            }
            return (
                <div>
                    <p className="lead">Online Application From For Membership</p>
                    <MembershipForm formData={formData} onSubmit={this.handleFormClick} />
                </div>
            )
        }
    }

    errorComponent(_error: any) {
        return (
            <Link className="btn" to={RoutePath.login} style={{ color: Theming.textColourDark, borderColor: Theming.textColourDark, backgroundColor: Theming.backgroundColourDark }}>Login to Buy Membership</Link>
        )
    }
}

export default withAppContext(Membership);