import { Membership, MembershipDetails } from '../models/membership';
import CoreService from './core_service';

class MembershipService extends CoreService {
	static _instance: MembershipService;
	static getInstance = () => {
		if (!MembershipService._instance) {
			return MembershipService._instance = new MembershipService();
		}
		return MembershipService._instance;
	};

	getMembershipStatus = async () => {
		const MembershipRes = await this._strapi.request(
			'GET',
			'membership/requests', {
			headers: this._withAuthHeader()
		}
		);

		return MembershipRes as Membership[];
	};

	applyMembership = async (membership: MembershipDetails, paymentMethod: 'bank_transfer' | 'upi' | 'other', paymentProof: File) => {
		const formData = new FormData();

		// Add the file to the form data
		formData.append('files.payment_proof', paymentProof);

		// Add the other form data to the form data
		formData.append('data', JSON.stringify({
			...membership,
			payment_mode: paymentMethod
		}));

		const MembershipRes = await this._strapi.request(
			'POST',
			'membership/request', {
				headers: {
					...this._withAuthHeader(),
					'Content-Type': 'multipart/form-data'
				},
				data: formData
			},
		);

		return MembershipRes as Membership;
	};

	cancelMembership = async (id: String) => {
		const MembershipRes = await this._strapi.request(
			'DELETE',
			`membership/requests/${id}`, {
			headers: this._withAuthHeader()
		}
		);

		return MembershipRes as Membership;
	};
}

export default MembershipService.getInstance();