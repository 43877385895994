import { User } from "firebase";
import React from "react";

export interface AppContextSchema {
	currentUser: User | null,
	updateUser: (user: User | null) => void,
	isAuthenticated: () => User | null
}

const appContextStore: AppContextSchema = {
	currentUser: null,
	updateUser(user: User | null) {
		appContextStore.currentUser = user;
	},
	isAuthenticated() {
		return (appContextStore.currentUser || null)
	}	
};

const AppContext = React.createContext(appContextStore);

export const withAppContext = <P extends object>(
	Component: React.ComponentType<P>,
): React.FC<Omit<P, keyof AppContextSchema>> => props => (
	<AppContext.Consumer>
		{(context) => <Component {...props as P} {...context} />}
	</AppContext.Consumer>
);

export default AppContext;
